/*eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import Axios from 'axios';
import ApiConfig from "_config/ApiConfig";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

// @material-ui/icons
import Camera from "@material-ui/icons/Camera";
//import Remove from "@material-ui/icons/Remove";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Badge from "components/Badge/Badge.js";

import ImageUpload from "components/CustomUpload/ImageUpload.js";

import ArrowForwardIcon from '@material-ui/icons/ArrowUpwardOutlined';
import CachedIcon from '@material-ui/icons/Cached';
import ArtTrack from "@material-ui/icons/ArtTrack";
import RotateRightIcon from '@material-ui/icons/RotateRight';

//import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import NavPills from "components/NavPills/NavPills.js";

import Muted from "components/Typography/Muted.js";
//import Parallax from "components/Parallax/Parallax.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Button from "components/CustomButtons/Button.js";

import SectionSliderUnit from "views/PresentationPage/Sections/SectionSliderUnit.js";

import SectionProductPage from "views/AddProductPage/Sections/SectionProductPage.js";

import SectionColor from "views/AddProductPage/Sections/SectionColor.js";
import SectionMateriau from "views/AddProductPage/Sections/SectionMateriau.js";
import SectionFinition from "views/AddProductPage/Sections/SectionFinition.js";
import SectionFournisseur from "views/AddProductPage/Sections/SectionFournisseur.js";
import SectionMarque from "views/AddProductPage/Sections/SectionMarque.js";
import SectionFabricant from "views/AddProductPage/Sections/SectionFabricant.js";

import ListeInventoryMove from "views/LocalisationPage/ListeInventoryPageMove.js";
import CompteurPageLocal from "views/CompteurPage/CompteurPageLocal.js";



import Accordion from "components/Accordion/Accordion.js";

import ImageGallery from "react-image-gallery";

import profilePageStyle from "assets/jss/material-kit-pro-react/views/profilePageStyle.js";

import { TextField } from '@material-ui/core/';
import { TextareaAutosize } from '@material-ui/core/';

const useStyles = makeStyles(profilePageStyle);

export default function UpdateProductPage({ ...rest }) {

  const [isitem, setitem] = useState([]);
  const [islibelle, setlibelle] = useState();

  const position = useRef(null);

  const [images, showPicture] = useState([]);
  const history = useHistory();

  //CONSTRUIT LE TABLEAU IMAGE GALLERY
  const getPicturesData = async (id) => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/GetPictures.php`, {
        id_std: sessionStorage.getItem("id_std"),
      })
        .then(res => {
          showPicture(res.data.picturedata);//alimente la gallery
          return;
        })
    } catch (error) { throw error; }
  };
  /*CHARGE les caracteristiques du produit*/
  const getItemData = () => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/GetItem.php`, {
        id_std: sessionStorage.getItem("id_std"),
      })
        .then(res => {
          setitem(res.data.itemdata);
          setlibelle(res.data.libelle);
          //console.log(res.data.libelle)
          return;
        })
    } catch (error) { throw error; }
  };

  const [addproductInfo, setproductInfo] = useState({
    designationLabel: '',
  });

  const delItemData = () => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/DelItem.php`, {
        id_std: sessionStorage.getItem("id_std"),
      })
        .then(res => {
          //console.log(res.data);
          if (res.data.success === true) {
            history.push("/catalogue-page");//REnvoyer vers la partie profil pour associer le compte A FAIRE
            // resultDeleteProduct('Votre produit est supprimé');
          }
          else {
            resultDeleteProduct('Identification du produit manquante');//poser le message sous lebouton
          }
          return;
        })
    } catch (error) { throw error; }
  };
  const onChangeValue = (e) => {
    setlibelle(e.target.value);

    setproductInfo({
      ...addproductInfo,
      [e.target.name]: e.target.value
    });
  }

  const classes = useStyles();

  const [messageMaj, resultMajProduct] = useState([]);
  const [messageUpdate, resultUpdateProduct] = useState([]);
  const [messageNotDelete, resultDeleteProduct] = useState([]);


  const updateToProduct = async (event) => {
    try {
      event.preventDefault();
      event.persist();
      Axios.defaults.withCredentials = true;

      Axios.post(`${ApiConfig.baseUrl}/Controls/UpdateToProduct.php`, {
        productId: sessionStorage.getItem("id_std"),
        productDesignation: addproductInfo.designationLabel,
        productType: sessionStorage.getItem("setSelectedEnabled"),
        productDescription: addproductInfo.descriptionArea,
        productHeight: sessionStorage.getItem("setHeightUnit"),
        productWidth: sessionStorage.getItem("setWidthUnit"),
        productDeph: sessionStorage.getItem("setDepthUnit"),
        productVolume: sessionStorage.getItem("setVolumeUnit"),
      })
        .then(res => {
          if (res.data.updateproductdata['success'] === true) {
            resultMajProduct('Votre produit est modifié');
          }
          else {
            resultMajProduct('Identification du produit manquante');
          }
        })
    } catch (error) { throw error; }
  };

  const addToProduct = async (event) => {
    try {
      event.preventDefault();
      event.persist();

      Axios.post(`${ApiConfig.baseUrl}/Controls/AddToProduct.php`, {
        idColor: sessionStorage.getItem("getColorSelect"),
        idMateriau: sessionStorage.getItem("getMateriauSelect"),
        idFinition: sessionStorage.getItem("getFinitionSelect"),
        idFournisseur: sessionStorage.getItem("getFournisseurSelect"),
        idMarque: sessionStorage.getItem("getMarqueSelect"),
        idFabricant: sessionStorage.getItem("getFabricantSelect"),
        productId: sessionStorage.getItem("id_std"),
      })
        .then(res => {
          //console.log(res)
          if (res.data.productupdatedata.success === true) {
            resultUpdateProduct('Votre produit est mis à jour');
          }
          else if (res.data.productupdatedata.success === false) {
            resultUpdateProduct('Echec de la Maj des caractéristiques');
          }
        })
    } catch (error) { throw error; }
  };

  const getItemRotate = async (id) => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/RotatePicture.php`, {
        id_std: id, position: position.current.state.currentIndex,
      })
        .then(res => {
          //console.log(res.data)
          getPicturesData();
          return;
        })
    } catch (error) { throw error; }
  };
  const easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };


  const smoothScrollClickTarget = (e, target) => {
    e.preventDefault();
    var targetScroll = document.getElementById(target);
    scrollGo(document.documentElement, targetScroll.offsetTop, 1250);
  };
  const scrollGo = (element, to, duration) => {
    var start = element.scrollTop,
      change = to - start,
      currentTime = 0,
      increment = 20;

    var animateScroll = function () {
      currentTime += increment;
      var val = easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    getItemData();
    getPicturesData();

  }, []);

  return (
    <div>
      <Header
        color="black"
        brand="FLEX GM"
        links={<HeaderLinks dropdownHoverColor="whiteColor" />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white"
        }}

      />

      <br /><br />      <br /><br />

      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={6}>
              <div className={classes.profile}>
                <br /><br /><br /><br /><br />
                <div className={classes.name}>
                  <h4 className={classes.title}>INTERFACE DE MAJ</h4>
                </div><div className={classNames(classes.description, classes.textCenter, classes.textBlue)}>
                  Sélectionnez une destination et une quantité pour ce produit.
                </div>
              </div>
            </GridItem>
          </GridContainer>

          <div className={classes.profileTabs}>
            <NavPills
              alignCenter
              color="info"
              tabs={[
                {
                  tabButton: "Fiche produit",
                  //tabIcon: Camera,
                  tabContent: (
                    <div className={classes.productPage}>
                      {isitem.map((item) => {
                        return (
                          <div className={classes.main}>
                            <GridContainer>
                              <GridItem md={12} sm={12}>
                                <ImageGallery
                                  showFullscreenButton={false}
                                  showPlayButton={false}
                                  startIndex={parseInt(sessionStorage.getItem("ctr_index"))}
                                  ref={position}
                                  items={images}
                                  showThumbnails={true}
                                  renderLeftNav={(onClick, disabled) => {
                                    return (
                                      <button
                                        className='image-gallery-left-nav'
                                        disabled={disabled}
                                        onClick={onClick} />
                                    );
                                  }}
                                  renderRightNav={(onClick, disabled) => {
                                    return (
                                      <button
                                        className='image-gallery-right-nav'
                                        disabled={disabled}
                                        onClick={onClick} />
                                    );
                                  }}
                                  renderCustomControls={(onClick) => {
                                    if (sessionStorage.getItem("statut_clt") === 'modeAdmin') {
                                      return (
                                        <Button
                                          color="transparent"
                                          size="sm"
                                          round
                                          onClick={() => getItemRotate(sessionStorage.getItem("id_std"))} >
                                          {/* <RotateRightIcon /> */}
                                        </Button>
                                      );
                                    }
                                  }} />
                              </GridItem>
                            </GridContainer>
                            <GridContainer>
                              <GridItem md={12} sm={12}>
                                <h2 className={classes.title}>{item.libelle}</h2>
                                <h3 className={classes.mainPrice}>{item.groupe}</h3>

                                <br /><br />
                                <br /><br />
                                <ListeInventoryMove />
                                {/*  <br /><br />   */}
                                <CompteurPageLocal
                                  id_std={sessionStorage.getItem("id_std")} />   <br /><br />
                                <GridContainer className={classes.pullRight}>
                                  <Link to="/catalogue-page">
                                    <Button round color="info"
                                      endIcon={<ArrowForwardIcon />}
                                    >
                                      Catalogue
                                    </Button>
                                  </Link>
                                </GridContainer>
                              </GridItem>
                            </GridContainer>
                          </div>
                        );
                      }
                      )}
                    </div>
                  )
                }
              ]}
            />
          </div>  <Clearfix />
        </div>
      </div>
      <br /><br />
    </div>
  );
}
