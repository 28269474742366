/*eslint-disable*/
import React, { useState, useEffect } from "react";
import Axios from 'axios';
import ApiConfig from "_config/ApiConfig";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
//import List from "@material-ui/core/List";
//import ListItem from "@material-ui/core/ListItem";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import SaveIcon from '@material-ui/icons/Save';

//import Muted from "components/Typography/Muted.js";
import Header from "components/Header/Header.js";
//import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Button from "components/CustomButtons/Button.js";
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import { TextField } from '@material-ui/core/';
import SectionLocalisation from "views/LocalisationPage/SectionLocalisation.js";
import KillItem from "views/ItemPage/KillItem.js";
import LoadItem from "views/ItemPage/LoadItem.js";
import Default from "components/ToggleVisibility/Default.js";
import ToggleVisibility from "components/ToggleVisibility/ToggleVisibility.js";
import ToggleVisibilityAddInput from "components/ToggleVisibility/ToggleVisibilityAddInput.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import profilePageStyle from "assets/jss/material-kit-pro-react/views/profilePageStyle.js";

const useStyles = makeStyles(profilePageStyle);

export default function LocalisationPage() {

  //PARTIE OWNER
  //LA LISTE
  const [isOwner, setOwner] = useState([]);
  const getOwner = async (ids) => {
    try {
        Axios.defaults.withCredentials = true;
      await Axios.get(`${ApiConfig.baseUrl}/Controls/GetOwner.php`)
        .then(res => {
          //console.log(res.data)
          setOwner(res.data.ownerdata);
          return;
        })
    } catch (error) { throw error; }
  };

  //recuperation dun element dans la liste
  const [ownerSelect, setSelected] = useState(null);
  const [showgetNameOwnerSelect, resultMessageOwner] = useState(['Aucun compte Client sélectionné']);//message par default

  const setOwnerSelect = (selectedOption) => {
    setSelected(selectedOption);
    reinitInventory();
    const ownerSelectArray = String(selectedOption).split(",");
    sessionStorage.setItem("getIdOwnerSelect", ownerSelectArray[0]);
    sessionStorage.setItem("getNameOwnerSelect", ownerSelectArray[1]);
    resultMessageOwner("Compte Client : " + ownerSelectArray[1]);

    //ON REINITIALISE POUR EVITER LA CONFUSION SUR LA LOCALISATION A CHAGE CHANGEMENT DE OWNER
    if (sessionStorage.getItem("spotLocalization")) {
      sessionStorage.removeItem("spotLocalization");
    }
    if (sessionStorage.getItem("spotLevel")) {
      sessionStorage.removeItem("spotLevel");
    }
    if (sessionStorage.getItem("spotZone")) {
      sessionStorage.removeItem("spotZone");
    }
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/GetInventory.php`, {
        id_pro: ownerSelectArray[0],
      })
        .then(res => {
          if (res.data.success === true) {
            setInventory(res.data.inventorydata);//appel des inventaires realisés
            resultFindInventory('');
          }
          else {
            setInventory(res.data.inventorydata);
            resultFindInventory('Sélectionnez un inventaire existant ou commencez en un');//on propose de supprimer l'inventaire

          }
          return;
        })
    } catch (error) { throw error; }
  };

  //ajout d'un owner

  const [addOwnerInfo, setAddOwnerInfo] = useState({
    designationLabel: '',
  });
  const onChangeValue = (e) => {
    setAddOwnerInfo({
      ...addOwnerInfo,
      [e.target.name]: e.target.value
    });
  }

  const addToOwner = async (event) => {
    try {
      event.preventDefault();
      event.persist();
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/AddOwner.php`, {
        productDesignation: addOwnerInfo.designationLabel,
      })
        .then(res => {
          if (res.data.ownerinsertdata.success === true) {
            if (sessionStorage.getItem("getIdOwnerSelect")) {
              sessionStorage.removeItem("getIdOwnerSelect");
            }
            if (sessionStorage.getItem("getNameOwnerSelect")) {
              sessionStorage.removeItem("getNameOwnerSelect");
            }
            sessionStorage.setItem("getIdOwnerSelect", res.data.ownerinsertdata.id_pro);
            sessionStorage.setItem("getNameOwnerSelect", res.data.ownerinsertdata.nom_pro);
            resultMessageOwner("Compte Client : " + res.data.ownerinsertdata.nom_pro);
          }
          else {
            resultMessageOwner('Saisie manquante');
          }
        })
    } catch (error) { throw error; }
  };

  const setOwnerInventory = () => {
    if (sessionStorage.getItem("getIdOwnerSelect")) {
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/GetInventory.php`, {
        id_pro: sessionStorage.getItem("getIdOwnerSelect"),
      })
        .then(res => {
          if (res.data.success === true) {
            setInventory(res.data.inventorydata);//appel des inventaires realisés
            resultFindInventory('');
          }
          else {
            setInventory(res.data.inventorydata);
            resultFindInventory('Sélectionnez un inventaire existant ou commencez en un');//on propose de supprimer l'inventaire

          }
          return;
        })
    } catch (error) { throw error; }
  }
  resultFindInventory('Aucun compte Client sélectionné');
  };

  //PARTIE INVENTORY

  const [isInventory, setInventory] = useState([]);
  const [inventorySelect, setinventorySelect] = useState([]);

  const reinitInventory = () => {
    setKillInventory(0);
    setLoadInventory(0);
  }

  const [message, resultFindInventory] = useState([]);
  const [messageAdd, resultCheckInventory] = useState([]);

  const [idInventory, setKillInventory] = useState(0);
  const [idLoadInventory, setLoadInventory] = useState(0);

  //au submit
  const updateInventorySelect = async (event) => {
    try {
      event.preventDefault();
      event.persist();
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/CheckInventory.php`, {
        id_zon: inventorySelect,
      })
        .then(res => {
          if (res.data.checkinventorydata.success === true) {
            resultCheckInventory('');
            setKillInventory(inventorySelect);
            setLoadInventory(inventorySelect);
            sessionStorage.setItem("spotLocalization", res.data.checkinventorydata.spotLocalization);
            sessionStorage.setItem("spotLevel", res.data.checkinventorydata.spotLevel);
            sessionStorage.setItem("spotZone", res.data.checkinventorydata.spotZone);
          }
          else {
            resultCheckInventory('Inventaire incomplet aucun article associé');//on propose de supprimer l'inventaire
            resultFindInventory('');
            setKillInventory(inventorySelect);
            setLoadInventory(inventorySelect);
            sessionStorage.setItem("spotLocalization", res.data.checkinventorydata.spotLocalization);
            sessionStorage.setItem("spotLevel", res.data.checkinventorydata.spotLevel);
            sessionStorage.setItem("spotZone", res.data.checkinventorydata.spotZone);
          }
        })

    } catch (error) { throw error; }
  };

  useEffect(() => {
    getOwner();//appelle la liste
  }, []);

  useEffect(() => {
    resultMessageOwner("Aucun compte Client sélectionné");
  }, []);

  const classes = useStyles();
  return (
    <div>
      <Header
        color="white"
        brand="FLEX GM"
        links={<HeaderLinks dropdownHoverColor="white" />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white"
        }}
      />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={6}>
              <div className={classes.profile}>
                <br /><br /><br /><br /><br /><br /><br /><br /><br />
                <div className={classes.name}>
                  <h4 className={classes.title}>SITUATION</h4>
                </div>
              </div>
            </GridItem>
          </GridContainer>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={10}>
              <div className={classNames(classes.main, classes.mainRaised_)}>
                <div className={classes.container_}>
                  <div className={classNames(classes.description, classes.textCenter, classes.textBlue)}>
                    <h4 className={classNames(classes.title, classes.textBlue)}>
                      Sélectionnez un compte Client dans la liste
                      <Clearfix />

                    </h4>
                    <FormControl
                      fullWidth
                      className={classes.selectFormControl}
                    >
                      <Select
                        MenuProps={{
                          className: classes.selectMenu
                        }}
                        classes={{
                          select: classes.select
                        }}
                        value={ownerSelect}
                        onChange={event => setOwnerSelect(event.target.value)}
                        inputProps={{
                          name: "ownerSelect",
                          id: "ownerSelect"
                        }}
                      >
                        {isOwner.map((item) => (
                          <MenuItem key={item.id_pro}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value={item.id_pro + "," + item.nom_pro}
                          >
                            {item.nom_pro}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  
                    <ToggleVisibilityAddInput>
                      <form onSubmit={addToOwner}>
                        <GridContainer>
                          <GridItem xs={6} sm={6} md={6}>
                            <TextField className={classes.input}
                              type="text"
                              // fullWidth
                              id="designationLabel"
                              color="primary"
                              name="designationLabel"
                              variant="standard"
                              onChange={onChangeValue}
                              label="Saisir un Client"
                              placeholder={" Information à saisir"}
                              autoComplete="off"
                              required
                            />
                          </GridItem>
                          <GridItem xs={3} sm={3} md={3}>
                            <Button color="info" size="sm" square="true"
                              onClick={addToOwner}
                            ><SaveIcon fontSize="large" />
                            </Button>
                          </GridItem>
                        </GridContainer>
                      </form>
                    </ToggleVisibilityAddInput>
                    <h4 className={classes.title}>{showgetNameOwnerSelect}</h4>
                  </div>
                </div>
              </div>
            </GridItem>
          </GridContainer>
          <br /><br />
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={10}>
              <CustomTabs
                plainTabs
                headerColor="info"
                tabs={[
                  {
                    tabName: "Commencer",
                    tabContent: (
                      <>
                        <label>COMMMENCEZ UN INVENTAIRE (Administrateur)</label>

                        <SectionLocalisation />
                        <ToggleVisibility>
                          <Default />
                        </ToggleVisibility>
                      </>
                    )
                  },
                  {
                    tabName: "Continuer",
                    tabContent: (


                      <div className={classNames(classes.main, classes.mainRaised_)}>
                        <div className={classes.container_}>
{/*                           <label>SELECTIONNEZ UN INVENTAIRE </label>
 */}                          
                          <Button color="info" size="sm" square="true"
                              onClick={setOwnerInventory}  
                            >SELECTIONNEZ UN INVENTAIRE
                            </Button>

                          <form onSubmit={updateInventorySelect}>
                            <FormControl
                              fullWidth
                              className={classes.selectFormControl}
                            >
                              <Select
                                MenuProps={{
                                  className: classes.selectMenu
                                }}
                                classes={{
                                  select: classes.select
                                }}
                                value={inventorySelect}

                                onChange={event => {
                                  setinventorySelect(event.target.value)
                                  reinitInventory()
                                }}
                                inputProps={{
                                  name: "inventorySelect",
                                  id: "inventorySelect"
                                }}
                              >
                                {isInventory.map((item) => (
                                  <MenuItem key={item.id_zon}
                                    classes={{
                                      root: classes.selectMenuItem,
                                      selected: classes.selectMenuItemSelected
                                    }}
                                    value={item.id_zon}
                                  >
                                    {item.design_loc + " " + item.cod_etg + " " + item.cod_zon}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>

                            <Button color="info" size="sm" square="true"
                              onClick={updateInventorySelect}
                            ><ArrowRightAltIcon fontSize="large" />
                            </Button>
                          </form>
                          <h6 className={classes.textWarning}>{message}</h6>
                          <h6 className={classes.textWarning}>{messageAdd}</h6>


                          <div>
                            <LoadItem
                              id_item={idLoadInventory}
                            />
                            <KillItem
                              id_item={idInventory}
                            />
                          </div>
                        </div>
                      </div>
                    )
                  },
                ]}
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <br /><br />
    </div>
  );
}
