
import React, { useState } from "react";
import Axios from 'axios';
import ApiConfig from "_config/ApiConfig";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Button from "components/CustomButtons/Button.js";
import SaveIcon from '@material-ui/icons/Save';

import styles from "assets/jss/material-kit-pro-react/views/ecommerceSections/catalogueOffersStyle.js";
import { TextField } from '@material-ui/core/';

const useStyles = makeStyles(styles);

export default function Compteur(props) {
  const classes = useStyles();

  const [messageEmptyLocalization, resultEmptyLocalization] = useState([]);
  const [messageConfirmAction, resultMessageConfirmAction] = useState([]);

  const { id_std } = props

  const [nivfin1Result, setNivfin1Result] = useState([]);
  const [nivfin2Result, setNivfin2Result] = useState([]);
  const [nivfin3Result, setNivfin3Result] = useState([]);
  const [nivfinResult, setNivfinResult] = useState([]);

  const [nivfin1Temp, setNivfin1ToTemp] = useState([]);
  const [nivfin2Temp, setNivfin2ToTemp] = useState([]);
  const [nivfin3Temp, setNivfin3ToTemp] = useState([]);

  const nivfinResultOnLoad = function (id_std) {
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/GetQteStd.php`, {
        id_std: id_std,
        idLocalization: sessionStorage.getItem("idLocalization"),
        idLevel: sessionStorage.getItem("idLevel"),
        idZon: sessionStorage.getItem("idZon"),
        id_pro: sessionStorage.getItem("getIdOwnerSelect"),
      })
        .then(res => {
          //console.log(res.data.query2)
          //console.log(res.data.qteStdData.nivfinstock)
          //console.log(res.data.qteStdData.nivfin2)

          setNivfin1Result(res.data.qteStdData.nivfin1);
          setNivfin2Result(res.data.qteStdData.nivfin2);
          setNivfin3Result(res.data.qteStdData.nivfin3);
          setNivfinResult(res.data.qteStdData.nivfinstock);
          return;
        })
    } catch (error) { throw error; }
  }

  let nivfin1Res;//celle que l on affiche
  let nivfin2Res;//celle que l on affiche
  let nivfin3Res;//celle que l on affiche
  let nivfinRes;//celle que l on affiche

  if (nivfinResult.length === 0) {
    nivfinResultOnLoad(id_std);
  }
  else {
    nivfinRes = nivfinResult;
    nivfin1Res = nivfin1Result;
    nivfin2Res = nivfin2Result;
    nivfin3Res = nivfin3Result;
  }

  const handleClick = (event) => {
    setNivfin1ToTemp(event.target.value);
  };
  const handleClick2 = (event) => {
    setNivfin2ToTemp(event.target.value);
  };
  const handleClick3 = (event) => {
    setNivfin3ToTemp(event.target.value);
  };

  /* setTimeout(() => {
    setQuantityItem(id_std, nbr2, 2);
 }, 2000);
 clearTimeout();
}; */


  const setQuantityItem1 = () => {
    //console.log(id_std, nivfin1Temp, 1);
    setQuantityItem(id_std, nivfin1Temp, 1);
  };
  const setQuantityItem2 = () => {
    // console.log(id_std, nivfin2Temp, 2);
    setQuantityItem(id_std, nivfin2Temp, 2);
  };
  const setQuantityItem3 = () => {
    //console.log(id_std, nivfin3Temp, 3);
    setQuantityItem(id_std, nivfin3Temp, 3);
  };
  const setQuantityItem = async (id, qte, quality) => {
    //console.log(qte)
    if (sessionStorage.getItem("statut_clt") === 'modeAdmin') {

      if (sessionStorage.getItem("spotLocalization") && sessionStorage.getItem("spotLevel") && sessionStorage.getItem("spotZone") && id) {
        try {
          Axios.defaults.withCredentials = true;
          Axios.post(`${ApiConfig.baseUrl}/Controls/AddItemQte.php`, {
            id_std: id,
            qte_std: qte,
            qualityStd: quality,
            idLocalization: sessionStorage.getItem("idLocalization"),
            idLevel: sessionStorage.getItem("idLevel"),
            idZon: sessionStorage.getItem("idZon"),
            id_pro: sessionStorage.getItem("getIdOwnerSelect"),
          })
            .then(res => {
              // console.log(res.data);

              if (res.data.success === true) {
                setNivfin1Result(res.data.itemCountData.nivfin1);
                setNivfin2Result(res.data.itemCountData.nivfin2);
                setNivfin3Result(res.data.itemCountData.nivfin3);
                setNivfinResult(res.data.itemCountData.nivfinstock);
                setNivfin1ToTemp('');
                setNivfin2ToTemp('');
                setNivfin3ToTemp('');
                resultMessageConfirmAction(res.data.itemCountData.qteStd + ' element(s) ' + res.data.itemCountData.qualityStd);// A voir si pour afficher un message apres validation
                resultEmptyLocalization('');
              }
              return;
            })
        } catch (error) { throw error; }
      }
      else {
        resultEmptyLocalization('Définissez la localisation');
      }
    }
    else {
      resultEmptyLocalization('Inventaire en consultation seulement');
    }
  };

  return <>
    <div className={classes.textCenter}>
      <br />
      <h6 className={classes.textWarning}>{messageEmptyLocalization}</h6>
      <h6 className={classes.textBlue}>{messageConfirmAction}</h6>

    </div>
    <div className={classes.mlAuto}>
      <span className={classNames(classes.price, classes.priceNew)}>
        Total: {nivfinRes}
      </span>
    </div>
    <GridContainer>
      <GridItem xs={4} sm={4} md={4}>
        <span key={1}>
          <div className={classes.buttonGroup}>
            Bon: {nivfin1Res}
            <br />
            {sessionStorage.getItem("statut_clt") === 'modeAdmin' ? 
            <>
            <TextField className={classes.input}
              type="text"
              onChange={handleClick}
              value={nivfin1Temp}
            />
              <Button
                color="info"
                size="sm"
                round
                onClick={setQuantityItem1}
              >
                <SaveIcon />
              </Button></>
             :
             <><hr></hr></>
            }
          </div>
        </span>
      </GridItem>
      <GridItem xs={4} sm={4} md={4}>
        <span key={2}>
          <div className={classes.buttonGroup}>
            Fonctionnel: {nivfin2Res}
            <br />
            {sessionStorage.getItem("statut_clt") === 'modeAdmin' ? 
            <>
            <TextField className={classes.input}
              type="text"
              onChange={handleClick2}
              value={nivfin2Temp}
            />
            <Button
              color="info"
              size="sm"
              round
              onClick={setQuantityItem2}>
              <SaveIcon />
            </Button></>
             :
             <><hr></hr></>
            }
          </div>
        </span>
      </GridItem>
      <GridItem xs={4} sm={4} md={4}>
        <span key={3}>
          <div className={classes.buttonGroup}>
            Hs: {nivfin3Res}
            <br />
            {sessionStorage.getItem("statut_clt") === 'modeAdmin' ? 
            <>
            <TextField className={classes.input}
              type="text"
              onChange={handleClick3}
              value={nivfin3Temp}
            />
            <Button
              color="info"
              size="sm"
              round
              onClick={setQuantityItem3}>
              <SaveIcon />
            </Button>
            </>
             :
             <><hr></hr></>
            }
          </div>
        </span>
      </GridItem>
    </GridContainer>
  </>
}