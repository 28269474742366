import React from "react";
import { Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import ArrowBackIcon from '@material-ui/icons/ArrowDownward';

export default function ToggleVisibilityUpdate() {

  // React state to manage visibility
  //const [show, setShow] = React.useState();

  // function to toggle the boolean value
  // function toggleShow() {
  //   setShow(!show);  
  // }  
  if (sessionStorage.getItem("statut_clt") === 'modeAdmin') {
  return (
    <>
    <Link to="/updateProductLocalisation-page">
    <Button round color="primary"
                      startIcon={<ArrowBackIcon />}
                      >
                          Bouger
                        </Button>
          </Link>
  </>   
  );
}else return false;
}

